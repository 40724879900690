import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import styles from './blog.module.css'
import '../components/base.css'

class Terms extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <div
        style={{ background: '#fff', maxWidth: '100vw', overflow: 'hidden' }}
      >
        <Helmet title={siteTitle} />
        <div className={styles.hero}>
          <h3>
            Walk<span style={{ color: '#fff' }}>Up</span> Terms and Conditions
          </h3>
          <p />
        </div>
        <div class="legal-container css-12f6h39 ez1yctd0">
          <h3>WALKUP TERMS AND CONDITIONS OF SERVICE</h3>
          <p>
            Welcome to Walkup.tech website and our applications. This page
            (together with the documents referred to on it) tells you the terms
            and conditions on which both we and our partners provide virtual
            queuing software for you. By using our virtual queueing software you
            agree to be bound by these terms and conditions and our terms of use
            policy. Your personal data is handled by WalkUp in accordance with
            our privacy policy. If you have any questions relating to these
            terms and conditions please contact us at hello@walkup.tech If you
            do not accept these terms and conditions in full please do not use
            our Service.
          </p>
          <h3>1. INFORMATION ABOUT US</h3>
          <p>
            The WalkUp app and website Walkup.tech is operated by WalkIn Limited
            (“Us”, “we” or “WalkUp”) incorporated and registered in England and
            Wales, with registered office at 1 Manchester Square, W1U 3AB,
            London. WalkIn’s company registration number is 10993703. WalkIn is
            a business that provides queuing and real time access solutions for
            retail stores, sites and other vendors (“partners”) on behalf of
            which we act as site to connect you the customer with our partners.
          </p>
          <h3>2. PURPOSE</h3>
          <p>
            The purpose of our Service is to provide a simple and convenient
            service to you, linking you to our partners and allowing you to join
            a queue virtually or gain access in real time to any of our partners
            to allow you to; dine at sites, shop in retail stores or be provided
            a service by any of our partners.
          </p>
          <h3>3. SERVICE AVAILABILITY</h3>
          <p>
            WalkUp offers a virtual queuing service with our partners throughout
            London and around the world. When using the WalkUp App you will
            start with a default radius to join queues remotely from. This is to
            ensure that you can receive accurate queuing information and ensure
            you arrive within a reasonable timeframe when your table becomes
            ready. If you are outside this distance you cannot check in remotely
            to this site. If you show up on time for sites which call you back
            your queueing radius will expand, if you do not show or leave the
            queue more than 10 minutes after joining or if you’re called back
            and leave the queue your rating will be negatively affected and the
            distance you can join queues from will be reduced. Operating hours
            will vary depending on local trading conditions and the availability
            of our site partners.
          </p>
          <h3>4. JOINING A QUEUE, MAKING A BOOKING OR GRABBING A TABLE</h3>
          <p>
            When you join the queue through our Service, a notification will be
            sent to you either via SMS, a weblink update or via our mobile
            application. When you make a booking a confirmation e-mail will be
            sent to you. You will act on your own behalf and not for any other
            person when you join the queue, make a booking or use another feature.
            WalkUp will act as agent on behalf of the partner when we accept your
            entry into a queue, or to make a booking at a site. The contract for
            the queue/booking or table you access through us will be between you
            and the site partner and will only be formed when you have been sent
            a notification stating you are in the queue or have made a booking;
            by SMS, a weblink update or in app for the queue and en e-mail for
            bookings. When registering an account with us please ensure that
            you have given us a correct telephone number, otherwise you will
            not receive confirmation you are in the queue. Please also ensure
            that you provide an accurate e-mail address when registering to join
            a queue via the app or when making a booking. When notified that you are
            to enter the site via SMS and/or App notification please return to
            the site as soon as possible. For a booking please arrive at your
            arrival time. It is the site partners discretion as to how long you
            have to return before they can give your place to another guest(s)
            or move your position in the queue. Our site partners also hold the
            right to refuse you entry to their site or premises. We do monitor
            our site partners closely and it is of utmost importance to us that
            they comply with our standards and help us to maintain our reputation.
            Please let us know if you have any comments relating to our site
            partners or in respect of the service in terms of joining a queue
            or grabbing a table and being seated by emailing us at hello@walkup.tech.
          </p>
          <h3>5. WAIT TIME</h3>
          <p>
            Our aim is to provide the best queuing/virtual check in experience
            possible. Our wait time calculation displayed in app or quoted to
            you by our site partners are an estimation and as a result are not
            100% accurate. On some occasions things do not always go to plan and
            factors, such as customer volume or table turnover time in our site
            partners occasionally prevent us from achieving our targets in
            regard to accurate wait time estimates. We will do our best to
            ensure that your wait time is quoted as accurately as possible.
          </p>
          <h3>6. LEAVING THE QUEUE OR CANCELLING A BOOKING</h3>
          <p>
            You have the right to leave a queue while waiting virtually by using
            in the queue weblink or the WalkUp app with no cost to yourself. You
            can also cancel a booking via the confirmation e-mail. However if
            you are notified that your table is ready and you do not return to
            the site this information will be stored on our database. If you
            repeatedly join queues at sites, are notified your table is
            ready and do not return to the site, WalkUp reserves the right to
            remove you as a user. WalkUp also reserves the right to notify our
            partner sites of this.
          </p>
          <h3>7. OUR LIABILITY</h3>
          <p>
            To the extent permitted by law, WalkUp provides our Service and
            content on an "as-is" and "as available" basis and we make no
            representation or warranty of any kind, express or implied,
            regarding the content or availability of our Service, or that it
            will be timely or error-free or that defects will be corrected.
            Subject as provided below, neither WalkUp nor any partners shall
            have any liability to you for any direct, indirect, special or
            consequential losses or damages arising in contract, tort (including
            negligence) or otherwise arising from your use of or your inability
            to use our Service. In the event that WalkUp or the partner is found
            to be liable to you our total aggregate liability is limited to the
            fee of £5. This does not include or limit in any way WalkUp’s or any
            partners liability for any matter for which it would be illegal for
            us or it to exclude, or attempt to exclude, our or its liability,
            including liability for death or personal injury caused by
            negligence or for fraud or fraudulent misrepresentation.
          </p>
          <h3>8. EVENTS OUTSIDE OUR CONTROL</h3>
          <p>
            No party shall be liable to the other for any delay or
            non-performance of its obligations under this Agreement arising from
            any cause beyond its control including, without limitation, any of
            the following: act of God, governmental act, war, fire, flood,
            explosion or civil commotion. For the avoidance of doubt, nothing
            shall excuse the Customer from any payment obligations under this
            Agreement.
          </p>
          <h3>9. NO AGENCY</h3>
          <p>
            Nothing in this Agreement is intended to constitute WalkUp as your
            agent or authorise WalkUp to enter into any commitments for or on
            your behalf.
          </p>
          <h3>10. WAIVER</h3>
          <p>
            Neither you, WalkUp nor the site partner shall be responsible to the
            others for any delay or non-performance of its obligations under
            this agreement arising from any cause beyond its control including,
            without limitation, any of the following: act of God, governmental
            act, war, fire, flood, explosion or civil commotion.
          </p>
          <h3>11. SEVERABILITY</h3>
          <p>
            If any provision of this agreement is judged to be illegal or
            unenforceable, the continuation in full force and effect of the
            remainder of the provisions shall not be prejudiced.
          </p>
          <h3>12. ENTIRE AGREEMENT</h3>
          <p>
            These terms contain the whole agreement between the parties relating
            to its subject matter and supersede all prior agreements,
            arrangements and understandings between the parties relating to that
            subject matter.
          </p>
          <h3>13. OUR RIGHT TO VARY THESE TERMS AND CONDITIONS</h3>
          <p>
            WalkUp may revise these terms of use at any time by amending this
            page. You are expected to check this page from time to time to take
            notice of any changes we make, as they are binding on you.
          </p>
          <h3>14. LAW AND JURISDICTION</h3>
          <p>
            The English courts will have jurisdiction over any claim arising
            from, or related to, any use of our Services. These terms of use and
            any dispute or claim arising out of or in connection with them or
            their subject matter or formation (including non-contractual
            disputes or claims) shall be governed by and construed in accordance
            with the law of England and Wales.
          </p>
          <h3>To see our privacy policy please click the link below</h3>
          <a href="https://blog.walkup.tech/privacy">WalkUp Privacy Policy</a>
        </div>
      </div>
    )
  }
}

export default Terms

export const pageQuery = graphql`
  query TermsQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
